import { directorUrl } from "@/utils";
import { download } from "@/utils/helpers/fileUtils";
import {
  getLocalDirectorToken,
  setLocalOdienceUser,
  setLocalUserFirstName,
  setLocalUserLastName,
} from "@/utils/helpers/localstorage";
import {
  removeEmail,
  removeInvite,
  setEmail,
  setInvitedEventId,
} from "@/utils/helpers/sessionStorage";
import { handleAsync } from "@/utils/helpers/Utils";
import {
  DEFAULT_AVATAR,
  OdienceUser,
  refreshDirectorBearerToken,
} from "@/utils/hooks/useDirectorAuthentication";
import { fileToBase64 } from "@/utils/messaging/conversation/conversationUtils";
import toast from "react-hot-toast";

export const isValidDisplayName = (name: string) => {
  const displayNameRegex = /^(?!.*\.\.)(?!.*[&=_+<>,@-])[A-Za-z0-9_.-]{3,15}$/;
  return displayNameRegex.test(name);
};
const TOAST_ID = "updateProfile";

export const checkIfFieldInUse = async (
  groupId: string,
  fieldType: "usernameExists" | "emailExists",
  fieldValue: string
) => {
  const apiUrl = `${directorUrl}/api/group/${groupId}/user/${fieldType}/${fieldValue}`;
  try {
    const response = await fetch(apiUrl, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${getLocalDirectorToken()}`,
      },
    });
    if (response.ok) {
      const data = await response.json();
      return data.exist;
    } else {
      console.error(`Error checking ${fieldType}:`, response.status);
      return true;
    }
  } catch (error) {
    console.error(`Error during ${fieldType} check:`, error);
    return true;
  }
};

export const prepareAvatar = async (
  croppedImage: Blob | null | undefined,
  selectedImage: File | null | undefined
) => {
  const imageToUpload =
    croppedImage || selectedImage || (await download(DEFAULT_AVATAR));
  const base64Image = await fileToBase64(imageToUpload);
  return base64Image.split(",")[1];
};

export const DISPLAY_NAME_MESSAGE =
  "Display name must be between 3 and 15 characters. No spaces, cannot start or end with a period (.), contain more than one period in a row, or contain any of the following special characters: (&) (=) (_) (-) (+) (,) (<) (>) (@).";

export const MAX_RETRY_ATTEMPTS = 3;

export const handleUpdateUser = async (
  apiUrl: string,
  requestData: { name: string; email?: string; avatar?: string }
): Promise<any | null> => {
  for (
    let retryAttempt = 0;
    retryAttempt < MAX_RETRY_ATTEMPTS;
    retryAttempt++
  ) {
    const [error, response] = await handleAsync(
      fetch(apiUrl, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${getLocalDirectorToken()}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestData),
      })
    );

    if (error) {
      await refreshDirectorBearerToken();
      console.error("Error fetching data:", error);
      return;
    } else if (response) {
      if (response.status === 401) {
        console.log("UNAUTHORIZED TOKEN PROFILE SCREEN");
      } else if (response.ok) {
        const [responseError, responseData] = await handleAsync(
          handleSuccessfulResponse(response)
        );

        if (responseError) {
          console.error("Error handling response data:", responseError);
          return;
        }

        return responseData;
      } else {
        console.error("Request failed with status:", response.status);
      }
    }
  }
  return null;
};

export const removeAuthenticatedUserVerifiedEmail = async (
  authenticatedUser: OdienceUser,
  email: string
): Promise<string | undefined> => {
  const [error] = await handleAsync(
    fetch(
      directorUrl +
        `/api/group/${authenticatedUser.group_id}/user/removeEmailFromAccount`,
      {
        method: "POST",
        body: JSON.stringify({
          email: email,
        }),
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${getLocalDirectorToken()}`,
          "Content-Type": "application/json",
        },
      }
    )
  );
  if (error) {
    console.error("Error Removing Email", error);
    toast(error);
    return;
  }
};

const handleSuccessfulResponse = async (
  response: Response
): Promise<any | null> => {
  const contentType = response.headers.get("content-type");
  if (contentType && contentType.includes("application/json")) {
    try {
      const responseData = await response.json();
      if (responseData) {
        return responseData;
      }
    } catch (error) {
      console.error("Error parsing JSON:", error);
    }
  } else {
    console.error("Non-JSON response received");
  }
  return null;
};

export const checkIfUserHasInvitedEmail = async (
  inviteId: string
): Promise<string | undefined> => {
  const [error, response] = await handleAsync(
    fetch(directorUrl + `/api/getEventFromInvitation/${inviteId}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${getLocalDirectorToken()}`,
      },
    })
  );
  if (error) {
    console.error("Error Removing Email", error);

    return;
  }
  if (response) {
    const data = await response.json();
    setEmail(data.email);
    setInvitedEventId(data.event_id);
    return data;
  }
};

export const sendEmailVerification = async (
  eventId: string,
  email: string
): Promise<string | undefined> => {
  const [error] = await handleAsync(
    fetch(directorUrl + `/api/sendVerifyLink/${eventId}`, {
      method: "POST",
      body: JSON.stringify({
        email: email,
      }),
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${getLocalDirectorToken()}`,
        "Content-Type": "application/json",
      },
    })
  );
  if (error) {
    console.error("Error Sending Email Verification", error);
    return;
  }
};

export const saveUpdatedUserProfile = async (
  authenticatedUser: OdienceUser,
  firstName: string,
  lastName: string,
  displayName: string,
  email: string,
  emailInvite: string | null,
  invitedId: string | null,
  invitedEventId: string | null,
  updateUserAvatar: (avatar: string) => void,
  updateUserDisplayName: (displayName: string) => void,
  updateUserEmail: (email: string) => void,
  editedImage?: string,
  selectedImage?: File | null,
  croppedImage?: Blob | null
) => {
  setLocalUserFirstName(firstName);
  setLocalUserLastName(lastName);

  const apiUrl = emailInvite
    ? `${directorUrl}/api/group/${authenticatedUser.group_id}/user/update/${authenticatedUser.user_id}/${invitedEventId}`
    : `${directorUrl}/api/group/${authenticatedUser.group_id}/user/update/${authenticatedUser.user_id}`;

  const requestData: {
    name: string;
    avatar?: string;
    email?: string;
  } = {
    name: displayName,
  };

  if (email) {
    if (authenticatedUser.email !== email) {
      requestData.email = email;
    } else if (
      authenticatedUser.emails &&
      authenticatedUser.emails.length > 0 &&
      authenticatedUser.emails.includes(email)
    ) {
      requestData.email = email;
    }
  } else if (authenticatedUser.emails) {
    requestData.email = authenticatedUser.emails[0];
  }

  // Only attempt to update the avatar if a new one is selected
  if (croppedImage || selectedImage) {
    const [error, avatar] = await handleAsync(
      prepareAvatar(croppedImage, selectedImage)
    );
    if (error) {
      console.error("Error preparing avatar:", error);
      return;
    } else if (avatar) {
      requestData.avatar = avatar;
    }
  } else if (editedImage === "" && authenticatedUser.avatar) {
    requestData.avatar = DEFAULT_AVATAR;
  }

  if (emailInvite) {
    removeEmail();
  }

  if (invitedId) {
    removeInvite();
  }
  const responseData = await handleUpdateUser(apiUrl, requestData);

  if (responseData) {
    toast.success("Profile updated successfully", { id: TOAST_ID });
    const updatedUser = {
      ...authenticatedUser,
      avatar: responseData.avatar,
      name: responseData.name,
      msisdn: responseData.msisdn,
      group_id: responseData.group_id,
      user_id: responseData.user_id,
      email: responseData.email,
      emails: responseData.emails,
    };
    setLocalOdienceUser(JSON.stringify(updatedUser));
    updateUserAvatar(responseData.avatar);
    updateUserDisplayName(responseData.name);
    updateUserEmail(responseData.email);
    return true;
  } else {
    toast.error("Error updating profile, please try again later.", {
      id: TOAST_ID,
    });
  }
};
